// src/TrainDetailsModal.js
import React, { useState, useEffect } from 'react';
import './TrainDetailsModal.css';

const TrainDetailsModal = ({ isOpen, onClose, train, currentStation }) => {
  const [liveTrainInfo, setLiveTrainInfo] = useState(null);

  useEffect(() => {
    if (isOpen && train && train.TrainTimetables && train.TrainTimetables[0]) {
      const fetchLiveTrainInfo = async () => {
        try {
          const trainNo = train.TrainTimetables[0].TrainInfo.TrainNo;
          const response = await fetch(`https://train-query-backend.vercel.app/api/train/${trainNo}`);
          if (!response.ok) {
            throw new Error('獲取列車即時資料失敗');
          }
          const data = await response.json();
          setLiveTrainInfo(data);
        } catch (error) {
          console.error('Error fetching live train info:', error);
        }
      };

      fetchLiveTrainInfo();
    }
  }, [isOpen, train]);

  if (!isOpen || !train || !train.TrainTimetables || !train.TrainTimetables[0]) return null;

  const currentTime = new Date();

  const getDelayedTime = (originalTime, delayMinutes) => {
    const [hours, minutes] = originalTime.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes + delayMinutes);
    return date.toTimeString().slice(0, 5);
  };

  const getCurrentStationInfo = () => {
    if (!liveTrainInfo || !liveTrainInfo.TrainLiveBoards || liveTrainInfo.TrainLiveBoards.length === 0) {
      return '無法獲取當前車站信息';
    }
    const liveInfo = liveTrainInfo.TrainLiveBoards[0];
    let status;
    switch(liveInfo.TrainStationStatus) {
      case 0:
        status = '離開';
        break;
      case 1:
        status = '到達';
        break;
      case 2:
        status = '進行中';
        break;
      default:
        status = '未知';
    }
    return `當前位置: ${liveInfo.StationName.Zh_tw}, 狀態: ${status}`;
  };

  const trainInfo = train.TrainTimetables[0].TrainInfo;

  return (
    <div className="train-details-modal-overlay" onClick={onClose}>
      <div className="train-details-modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>車次 {trainInfo.TrainNo} 詳細資訊</h2>
        <div className="train-info">
          <p><strong>車種:</strong> {trainInfo.TrainTypeName.Zh_tw}</p>
          <p><strong>起點:</strong> {trainInfo.StartingStationName.Zh_tw}</p>
          <p><strong>終點:</strong> {trainInfo.EndingStationName.Zh_tw}</p>
          <p><strong>延誤:</strong> {liveTrainInfo && liveTrainInfo.TrainLiveBoards && liveTrainInfo.TrainLiveBoards[0] 
            ? `${liveTrainInfo.TrainLiveBoards[0].DelayTime} 分鐘` 
            : '獲取中...'}
          </p>
          <p><strong>當前狀態:</strong> {getCurrentStationInfo()}</p>
          <p><strong>更新時間:</strong> {liveTrainInfo ? new Date(liveTrainInfo.UpdateTime).toLocaleString() : '獲取中...'}</p>
        </div>
        <div className="stop-times-container">
          <div className="stop-times">
            {train.TrainTimetables[0].StopTimes.map((stop, index) => {
              const delayedTime = liveTrainInfo && liveTrainInfo.TrainLiveBoards && liveTrainInfo.TrainLiveBoards[0]
                ? getDelayedTime(stop.DepartureTime, liveTrainInfo.TrainLiveBoards[0].DelayTime)
                : stop.DepartureTime;
              const delayedDateTime = new Date(`${train.TrainDate} ${delayedTime}`);
              const isPast = delayedDateTime < currentTime;
              const isCurrent = liveTrainInfo && liveTrainInfo.TrainLiveBoards && liveTrainInfo.TrainLiveBoards[0] &&
                                stop.StationName.Zh_tw === liveTrainInfo.TrainLiveBoards[0].StationName.Zh_tw;
              return (
                <div 
                  key={index} 
                  className={`stop-time ${isPast ? 'past' : ''} ${isCurrent ? 'current' : ''}`}
                >
                  <span>{stop.StationName.Zh_tw}</span>
                  <span>
                    {stop.DepartureTime}
                    {liveTrainInfo && liveTrainInfo.TrainLiveBoards && liveTrainInfo.TrainLiveBoards[0] && liveTrainInfo.TrainLiveBoards[0].DelayTime > 0 
                      && ` (預計 ${delayedTime})`}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainDetailsModal;
