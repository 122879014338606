// src/StationInfo.js
import React, { useState, useEffect, useMemo, useRef } from 'react';
import useDebounce from './hooks/useDebounce';
import Modal from './Modal';
import TrainDetailsModal from './TrainDetailsModal';
import stationsData from './stations.json';

const TRAIN_TYPES = {
  '區間': { class: 'train-type-local', color: '#0072C6' },
  '普悠瑪': { class: 'train-type-express', color: '#FF6B00' },
  '太魯閣': { class: 'train-type-express', color: '#FF6B00' },
  '自強': { class: 'train-type-tze-chiang', color: '#E60012' },
  '莒光': { class: 'train-type-chu-kuang', color: '#00A040' },
  '復興': { class: 'train-type-fu-hsing', color: '#9E005D' },
  '其他': { class: 'train-type-other', color: '#888888' },
};

const getTrainTypeClass = (trainType) => {
  for (const [key, value] of Object.entries(TRAIN_TYPES)) {
    if (trainType.includes(key)) {
      return value.class;
    }
  }
  return TRAIN_TYPES['其他'].class;
};

const StationInfo = () => {
  const [selectedStation, setSelectedStation] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [stationData, setStationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState('auto');
  const dropdownRef = useRef(null);
  const [selectedTrain, setSelectedTrain] = useState(null);
  const [isTrainModalOpen, setIsTrainModalOpen] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const filteredStations = useMemo(() => {
    return stationsData.filter(station => 
      station.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
      station.code.includes(debouncedSearchTerm)
    );
  }, [debouncedSearchTerm]);

  const selectedStationCode = stationsData.find(station => station.name === selectedStation)?.code;
  const debouncedStationId = useDebounce(selectedStationCode, 500);

  useEffect(() => {
    const fetchStationData = async () => {
      if (!debouncedStationId) {
        setStationData(null);
        setError(null);
        return;
      }
      
      setLoading(true);
      try {
        const response = await fetch(`https://train-query-backend.vercel.app/api/station/${debouncedStationId}`);
        if (!response.ok) {
          throw new Error('獲取車站資料失敗');
        }
        const data = await response.json();
        if (data && data.length > 0) {
          setStationData(data);
          setError(null);
        } else {
          throw new Error('找不到該車站的資料');
        }
      } catch (err) {
        setError(err.message);
        setStationData(null);
        setModalMessage(err.message);
        setIsModalOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchStationData();
  }, [debouncedStationId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isDropdownOpen) {
      const height = Math.min(filteredStations.length * 40, 200);
      setDropdownHeight(`${height}px`);
    } else {
      setDropdownHeight('0px');
    }
  }, [isDropdownOpen, filteredStations]);

  const handleStationSelect = (station) => {
    setSelectedStation(station.name);
    setSearchTerm(station.name);
    setIsDropdownOpen(false);
  };

  const handleTrainClick = async (train) => {
    setLoading(true);
    try {
      const response = await fetch(`https://train-query-backend.vercel.app/api/traintime/${train.TrainNo}`);
      if (!response.ok) {
        throw new Error('獲取列車資料失敗');
      }
      const data = await response.json();
      setSelectedTrain(data);
      setIsTrainModalOpen(true);
    } catch (err) {
      setError(err.message);
      setModalMessage(err.message);
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="station-info">
      <div className="search-container" ref={dropdownRef}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setIsDropdownOpen(true);
          }}
          onFocus={() => setIsDropdownOpen(true)}
          placeholder="搜尋車站名稱或代碼"
          className="station-search"
        />
        <div 
          className={`station-dropdown ${isDropdownOpen ? 'open' : ''}`}
          style={{ maxHeight: dropdownHeight }}
        >
          {filteredStations.map((station) => (
            <div 
              key={station.code} 
              className="station-option"
              onClick={() => handleStationSelect(station)}
            >
              {station.name} ({station.code})
            </div>
          ))}
        </div>
        <button disabled={loading || !selectedStation} onClick={() => setIsDropdownOpen(false)}>
          {loading ? '查詢中...' : '查詢'}
        </button>
      </div>
      {!selectedStation && (
        <div className="info-message">請選擇一個車站</div>
      )}
      {loading && <div className="loading">載入中...</div>}
      {stationData && stationData.length > 0 && (
        <div className="result">
          <h2>{selectedStation} 站列車資訊</h2>
          <div className="info-grid">
            {stationData.map((train, index) => (
              <div key={index} className="info-item" onClick={() => handleTrainClick(train)}>
                <div className="train-header">
                  <span className="label">車次：{train.TrainNo}</span>
                  <span className={`train-type ${getTrainTypeClass(train.TrainTypeName?.Zh_tw || '')}`}>
                    {train.TrainTypeName?.Zh_tw || '未知類型'}
                  </span>
                </div>
                <div className="train-details">
                  <div><span className="label">終點站：</span><span className="value">{train.EndingStationName?.Zh_tw}</span></div>
                  <div><span className="label">預定到達：</span><span className="value">{train.ScheduledArrivalTime}</span></div>
                  <div><span className="label">預定離開：</span><span className="value">{train.ScheduledDepartureTime}</span></div>
                  <div><span className="label">延誤：</span><span className="value">{train.DelayTime} 分鐘</span></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Modal 
        isOpen={isModalOpen}
        message={modalMessage}
        onClose={() => setIsModalOpen(false)}
      />
      <TrainDetailsModal
        isOpen={isTrainModalOpen}
        onClose={() => setIsTrainModalOpen(false)}
        train={selectedTrain}
        currentStation={selectedStation}
      />
    </div>
  );
};

export default StationInfo;
