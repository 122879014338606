// src/HomePage.js
import React from 'react';

const HomePage = ({ onSelectMode }) => {
  return (
    <div className="home-page">
      <h1>智能列車動態查詢系統</h1>
      <div className="selection-buttons">
        <button onClick={() => onSelectMode('train')}>查詢車輛動態</button>
        <button onClick={() => onSelectMode('station')}>查詢車站資訊</button>
      </div>
    </div>
  );
};

export default HomePage;
