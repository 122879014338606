// src/App.js
import React, { useState } from 'react';
import HomePage from './HomePage';
import TrainInfo from './TrainInfo';
import StationInfo from './StationInfo';
import Modal from './Modal';
import './App.css';

function App() {
  const [viewMode, setViewMode] = useState('home');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleSelectMode = (mode) => {
    setViewMode(mode);
  };

  const handleBackToHome = () => {
    setViewMode('home');
  };

  return (
    <div className="app">
      <div className="container">
        {viewMode === 'home' && <HomePage onSelectMode={handleSelectMode} />}
        {viewMode !== 'home' && (
          <div className="header">
            <h2>{viewMode === 'train' ? '車輛動態查詢' : '車站資訊查詢'}</h2>
            <button className="back-button" onClick={handleBackToHome}>
              返回首頁
            </button>
          </div>
        )}
        {viewMode === 'train' && <TrainInfo />}
        {viewMode === 'station' && <StationInfo />}
      </div>
      <Modal 
        isOpen={isModalOpen}
        message={modalMessage}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export default App;
