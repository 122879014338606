// src/TrainInfo.js
import React, { useState } from 'react';

const TrainInfo = () => {
  const [trainNo, setTrainNo] = useState('');
  const [trainInfo, setTrainInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const searchTrain = async () => {
    if (!trainNo) return;
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://train-query-backend.vercel.app/api/train/${trainNo}`);
      if (!response.ok) {
        throw new Error('查詢失敗');
      }
      const data = await response.json();
      if (data.TrainLiveBoards && data.TrainLiveBoards.length > 0) {
        setTrainInfo(data.TrainLiveBoards[0]);
      } else {
        setError('未查詢到資料');
      }
    } catch (err) {
      setError('查詢失敗，請稍後再試');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="train-info">
      {/*<h2>車輛動態查詢</h2>*/}
      <div className="search-container">
        <input 
          type="text" 
          value={trainNo} 
          onChange={(e) => setTrainNo(e.target.value)} 
          placeholder="請輸入車輛編號"
        />
        <button onClick={searchTrain} disabled={loading}>
          {loading ? '查詢中...' : '查詢'}
        </button>
      </div>
      {error && <div className="error">{error}</div>}
      {trainInfo && (
        <div className="result">
          <h2>列車資訊</h2>
          <div className="info-grid">
            <div className="info-item">
              <span className="label">車次：</span>
              <span className="value">{trainInfo.TrainNo}</span>
            </div>
            <div className="info-item">
              <span className="label">車種：</span>
              <span className="value">{trainInfo.TrainTypeName?.Zh_tw}</span>
            </div>
            <div className="info-item">
              <span className="label">目前站點：</span>
              <span className="value">{trainInfo.StationName?.Zh_tw}</span>
            </div>
            <div className="info-item">
              <span className="label">延誤時間：</span>
              <span className="value">{trainInfo.DelayTime} 分鐘</span>
            </div>
            <div className="info-item">
              <span className="label">更新時間：</span>
              <span className="value">{new Date(trainInfo.UpdateTime).toLocaleString()}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrainInfo;
